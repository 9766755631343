import React from 'react';
import PropTypes from 'prop-types';

const PillItem = ({ children, isClickable, variant, ...rest }) => {
  return (
    <div
      className={`pill-item pill-item--${variant} pill-item--${
        isClickable ? 'clickable' : 'not-clickable'
      }`}
    >
      <div {...rest} className={`pill-item__inner`}>
        {children}
      </div>
    </div>
  );
};

PillItem.propTypes = {
  variant: PropTypes.oneOf(['default']),
  isClickable: PropTypes.bool,
};
PillItem.defaultProps = {
  variant: 'default',
  isClickable: true,
};

const PillsList = ({ children }) => {
  return (
    <div className="pills-list">
      <div className="pills-list__inner">{children}</div>
    </div>
  );
};

export { PillsList, PillItem };
